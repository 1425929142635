<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-card>
            <v-card-title>
              PC
              <v-spacer></v-spacer>
              <v-text-field
                v-model="datatable.search"
                append-icon="search"
                label="Search"
                single-line
                @change="searchProducts()"
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-data-table
                  :mobile-breakpoint="0"
                  :single-expand="true"
                  :headers="datatable.headers"
                  :items="datatable.desserts"
                  :server-items-length="datatable.total"
                  :loading="datatable.isLoading"
                  :options.sync="options"
                  :footer-props="{
                    showFirstLastPage: true,
                    itemsPerPageOptions: [5, 10, 20, 50, 100]
                  }"
                  item-key="id"
                  show-expand
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      v-b-tooltip="'Add product'"
                      small
                      class="mr-2"
                      @click="openProductsDialog(item)"
                      >mdi-plus-circle
                    </v-icon>
                  </template>
                  <template
                    v-slot:expanded-item="{ headers, item: parentItem }"
                  >
                    <td :colspan="headers.length">
                      <v-alert type="error" v-if="parentItem.error">
                        {{ parentItem.error }}
                      </v-alert>

                      <v-row>
                        <v-col cols="2"><h5>Required Items:</h5></v-col>
                        <v-col
                          v-for="itemHead in [
                            'MB',
                            'CP',
                            'RM',
                            'HD',
                            'GP',
                            'PS',
                            'CA',
                            'FA',
                            'CC'
                          ]"
                          :key="itemHead"
                        >
                          <b-form-checkbox
                            :id="itemHead"
                            type="checkbox"
                            v-model="parentItem.required_items"
                            :value="itemHead"
                            >{{ itemHead }}
                          </b-form-checkbox>
                        </v-col>
                        <v-col cols="3">
                          <b-button
                            type="button"
                            @click="saveRequiredItems(parentItem)"
                            variant="primary"
                            >Save
                          </b-button>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :mobile-breakpoint="0"
                            :headers="child_products.headers"
                            :items="parentItem.child_products"
                            item-key="id"
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                v-if="item.related_product.development_option"
                                v-b-tooltip="'Remove'"
                                small
                                class="mr-2"
                                @click="removeRelatedProduct(item, parentItem)"
                                >mdi-minus-circle
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </transition>
    <v-row justify="center">
      <v-dialog v-model="products_dialog" persistent max-width="900px">
        <v-card>
          <v-card-title>
            <span class="headline">Select Product Development Option</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="select_products_datatable.search"
              append-icon="search"
              label="Search"
              single-line
              @change="searchChildProductsSelect()"
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :mobile-breakpoint="0"
                :headers="select_products_datatable.headers"
                :items="select_products_datatable.desserts"
                :server-items-length="select_products_datatable.total"
                :loading="select_products_datatable.isLoading"
                :options.sync="select_products_datatable_options"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageOptions: [5, 10, 20, 50, 100]
                }"
                show-select
                v-model="select_products_datatable.selected"
                item-key="id"
              >
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="products_dialog = false"
              >Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="appendProductsToPC()"
              >Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<style scoped lang="scss">
@import "../../../../node_modules/vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ApiService from "../../../core/services/api.service";
import Vue from "vue";
import _ from "lodash";

const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function(msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

export default {
  name: "Orders",
  data() {
    return {
      test: [],
      products_dialog: false,
      selected_product: {},
      child_products: {
        headers: [
          { text: "Title", value: "title", width: "30%" },
          { text: "SKU", value: "sku", width: "15%" },
          { text: "Store Id", value: "channel_product_id", width: "10%" },
          { text: "Edara Id", value: "edara_id", width: "10%" },
          { text: "Type", value: "type", width: "10%" },
          { text: "Price", value: "price", width: "10%" },
          { text: "Stock Status", value: "stock_status", width: "12%" },
          { text: "Actions", value: "actions", width: "8%", sortable: false }
        ]
      },
      options: {},
      select_products_datatable_options: {},
      select_products_datatable: {
        selected: [],
        total: 0,
        search: "",
        isLoading: false,
        headers: [
          { text: "Title", value: "title", align: "left", width: "35%" },
          { text: "SKU", value: "sku", width: "17%" },
          { text: "Store Id", value: "channel_product_id", width: "15%" },
          { text: "Type", value: "type", width: "10%" },
          { text: "Price", value: "price", width: "10%" },
          { text: "Stock Status", value: "stock_status", width: "18%" }
        ],
        desserts: []
      },
      datatable: {
        total: 0,
        search: "",
        isLoading: false,
        headers: [
          { text: "Title", value: "title", align: "left", width: "35%" },
          { text: "SKU", value: "sku", width: "17%" },
          { text: "Store Id", value: "channel_product_id", width: "10%" },
          { text: "Type", value: "type", width: "10%" },
          { text: "Price", value: "price", width: "10%" },
          { text: "Stock Status", value: "stock_status", width: "12%" },
          { text: "Actions", value: "actions", width: "14%", sortable: false },
          { text: "", value: "data-table-expand", width: "1%", sortable: false }
        ],
        desserts: []
      }
    };
  },
  components: {},
  watch: {
    options: {
      handler() {
        this.loadProducts(this.options, this.datatable);
      },
      deep: true
    },
    select_products_datatable_options: {
      handler() {
        this.loadSelectChildProducts(
          this.selected_product.id,
          this.select_products_datatable_options,
          this.select_products_datatable
        );
      },
      deep: true
    }
  },
  methods: {
    openProductsDialog(item) {
      this.selected_product = item;
      this.products_dialog = true;
      this.loadSelectChildProducts(
        this.selected_product.id,
        this.select_products_datatable_options,
        this.select_products_datatable
      );
    },
    appendProductsToPC() {
      let childIds = this.select_products_datatable.selected.map(
        item => item.id
      );
      ApiService.post(`products/${this.selected_product.id}/append-products`, {
        child_product_ids: childIds
      }).then(({ data }) => {
        this.selected_product.child_products = data;
      });
      this.select_products_datatable.selected = [];
      this.products_dialog = false;
    },
    removeRelatedProduct(item, parentItem) {
      ApiService.get(
        `products/${item.related_product.parent_id}/children/${item.related_product.child_id}/detach`
      ).then(({ data }) => {
        parentItem.child_products = data.child_products;
      });
    },
    saveRequiredItems(parentItem) {
      ApiService.put(`products/${parentItem.id}/required-items`, {
        required_items: parentItem.required_items
      }).then(() => {
        this.makeToast("success", "Required Items Saved successfully");
      });
    },
    loadProducts(options, datatable) {
      datatable.isLoading = true;
      let sortBy = "";
      let sortDesc = false;
      if (options.sortBy.length == 0) {
        sortBy = "title";
        sortDesc = true;
      } else {
        sortBy = options.sortBy[0];
        sortDesc = options.sortDesc[0];
      }

      ApiService.get("pc", {
        per_page: options.itemsPerPage,
        page: options.page,
        sortBy: sortBy,
        sort: sortDesc ? "DESC" : "ASC",
        search: datatable.search
      })
        .then(({ data }) => {
          datatable.total = data.total;

          datatable.desserts = data.data.map(item => {
            return item;
          });
        })
        .finally(() => {
          datatable.isLoading = false;
        });
    },
    loadSelectChildProducts(product_id, options, datatable) {
      datatable.isLoading = true;
      let sortBy = "";
      let sortDesc = false;
      if (options.sortBy.length == 0) {
        sortBy = "title";
        sortDesc = true;
      } else {
        sortBy = options.sortBy[0];
        sortDesc = options.sortDesc[0];
      }

      ApiService.get(`products/${product_id}/child-products-select`, {
        per_page: options.itemsPerPage,
        page: options.page,
        sortBy: sortBy,
        sort: sortDesc ? "DESC" : "ASC",
        search: datatable.search
      })
        .then(({ data }) => {
          datatable.total = data.total;
          datatable.desserts = data.data;
        })
        .finally(() => {
          datatable.isLoading = false;
        });
    },
    searchProducts() {
      this.options.page = 1;
      this.loadProducts(this.options, this.datatable);
    },
    searchChildProductsSelect() {
      this.select_products_datatable_options.page = 1;
      this.loadSelectChildProducts(
        this.selected_product.id,
        this.select_products_datatable_options,
        this.select_products_datatable
      );
    },
    getStatusColor(status) {
      switch (status) {
        case "failed":
          return "red";
        case "sent":
          return "green";
        case "pending":
          return "orange";
      }
      return "secondry";
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: `PC`,
        variant: variant,
        solid: true
      });
    }
  },
  created() {
    this.loadProducts = _.debounce(this.loadProducts, 300);
    this.loadSelectChildProducts = _.debounce(
      this.loadSelectChildProducts,
      300
    );
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "PC", route: "PC" }]);
    this.loadProducts(this.options, this.datatable);
  }
};
</script>
