var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card',[_c('v-card-title',[_vm._v(" PC "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"change":function($event){return _vm.searchProducts()}},model:{value:(_vm.datatable.search),callback:function ($$v) {_vm.$set(_vm.datatable, "search", $$v)},expression:"datatable.search"}})],1),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"mobile-breakpoint":0,"single-expand":true,"headers":_vm.datatable.headers,"items":_vm.datatable.desserts,"server-items-length":_vm.datatable.total,"loading":_vm.datatable.isLoading,"options":_vm.options,"footer-props":{
                  showFirstLastPage: true,
                  itemsPerPageOptions: [5, 10, 20, 50, 100]
                },"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Add product'),expression:"'Add product'"}],staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openProductsDialog(item)}}},[_vm._v("mdi-plus-circle ")])]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var parentItem = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(parentItem.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(parentItem.error)+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('h5',[_vm._v("Required Items:")])]),_vm._l(([
                          'MB',
                          'CP',
                          'RM',
                          'HD',
                          'GP',
                          'PS',
                          'CA',
                          'FA',
                          'CC'
                        ]),function(itemHead){return _c('v-col',{key:itemHead},[_c('b-form-checkbox',{attrs:{"id":itemHead,"type":"checkbox","value":itemHead},model:{value:(parentItem.required_items),callback:function ($$v) {_vm.$set(parentItem, "required_items", $$v)},expression:"parentItem.required_items"}},[_vm._v(_vm._s(itemHead)+" ")])],1)}),_c('v-col',{attrs:{"cols":"3"}},[_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.saveRequiredItems(parentItem)}}},[_vm._v("Save ")])],1)],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.child_products.headers,"items":parentItem.child_products,"item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(item.related_product.development_option)?_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Remove'),expression:"'Remove'"}],staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.removeRelatedProduct(item, parentItem)}}},[_vm._v("mdi-minus-circle ")]):_vm._e()]}}],null,true)})],1)],1)],1)]}}])})],1)],1)],1)],1)])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.products_dialog),callback:function ($$v) {_vm.products_dialog=$$v},expression:"products_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Select Product Development Option")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"change":function($event){return _vm.searchChildProductsSelect()}},model:{value:(_vm.select_products_datatable.search),callback:function ($$v) {_vm.$set(_vm.select_products_datatable, "search", $$v)},expression:"select_products_datatable.search"}})],1),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.select_products_datatable.headers,"items":_vm.select_products_datatable.desserts,"server-items-length":_vm.select_products_datatable.total,"loading":_vm.select_products_datatable.isLoading,"options":_vm.select_products_datatable_options,"footer-props":{
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 20, 50, 100]
              },"show-select":"","item-key":"id"},on:{"update:options":function($event){_vm.select_products_datatable_options=$event}},model:{value:(_vm.select_products_datatable.selected),callback:function ($$v) {_vm.$set(_vm.select_products_datatable, "selected", $$v)},expression:"select_products_datatable.selected"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.products_dialog = false}}},[_vm._v("Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.appendProductsToPC()}}},[_vm._v("Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }